export default{
    state: {
        //船东直聘根据条件查询列表
        personList:[],
        //船东直聘根据条件查询列表的总条数
        count:0,
        loading:false,
        list:{}
    },
    getters: {
    },
    mutations: {
        //修改船东直聘根据条件查询列表的总条数和数据列表
        setperson(state,data){
            data.data.forEach(item => {
                item.gender = item.gender == '1' ? '男' : '女'
            });
            console.log(data)
            state.personList = data.data
            state.count =data.count
            state.loading =false
        },
        //船东直聘根据条件查询列表的加载状态
        setloading(state,data){
            state.loading = data
        },
        //船东直聘搜索条件
        setform(state,data){
            state.list = data
        }
    },
    actions: {
    },
    modules: {
    }
  }