import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import recruiting from './models/recruiting'
export default new Vuex.Store({
  modules: {
    recruiting
  }
})
