<template>
  <div class="login-content">
    <div class="container-box">
      <img src="../../../public/logo.png" alt="LOGO" class="logo" />

      <el-form :model="formQuery" ref="formQuery" :rules="rules" class="ruleForm">
        <el-form-item prop="username">
          <el-input v-model="formQuery.username" placeholder="账号名" class="input">
            <template #prefix>
              <i class="el-input__icon el-icon-user"></i>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input v-model="formQuery.password" placeholder="登录密码" show-password class="input">
            <template #prefix>
              <i class="el-input__icon el-icon-lock"></i>
            </template>
          </el-input>
        </el-form-item>
      </el-form>

      <el-button
        class="login-btn"
        @click="login"
        :plain="true"
      >登录</el-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            formQuery:{
                username:'',
                password:''
            },
            rules:{
                username:[
                    {
                        required:true,
                        trigger:'change',
                        message:'请输入用户名'
                    }
                ],
                password:[
                    {
                        required:true,
                        trigger:'change',
                        message:'请输入密码' 
                    }
                ]
            }
        }
    },
    methods:{
        login(){
            this.$refs.formQuery.validate(valid=>{
                if(valid){
                  const loading = this.$loading({
                      lock: true,
                      text: '登录中',
                      spinner: 'el-icon-loading',
                      background: 'rgba(0, 0, 0, 0.7)'
                    });
                    console.log(JSON.stringify(this.formQuery))
                    //填上这句代码可以请求时可以携带cookie
                    axios.defaults.withCredentials = true
                    axios({
                        method:'POST',
                        url:'https://hanghai.chhedu.com/admin/common/login',
                        data:this.formQuery,
                    }).then(({data})=>{  
                      console.log(data)
                        if(data.code == 1){
                          sessionStorage.setItem("token", data.data.access_token); 
                          loading.close()
                          this.$message({
                                    message: data.msg,
                                    center: true,
                                    type:'success'
                                  });
                          this.$router.push({
                            path:'/reindex'
                          })
                        }else{
                          loading.close()
                          this.$message({
                                    message: data.msg,
                                    center: true,
                                    type:'error'
                          });
                        }
                    }).catch(err=>{
                        console.log(err)
                    })
                }
            })
        }
    }
}

</script>

<style lang="scss">
.login-content {
  width: 100%;
  height: 100%;
  background: #323232;
  display: flex;
  justify-content: center;
  align-items: center;

  .container-box {
    width: 440px;
    height: 440px;
    background: #ffffff;
    box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .ruleForm {
      width: 440px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .el-form-item {
        margin-bottom: 30px;
      }
    }

    .logo {
      width: 120px;
      height: 120px;
      margin: 30px 0 20px;
    }

    .input {
      width: 360px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px;
      // margin-bottom: 30px;
    }

    .login-btn {
      width: 360px;
      height: 40px;
      color: #ffffff;
      background: #3474bb;
      border-radius: 4px;
    }
  }
}
</style>