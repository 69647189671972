import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/login/LoginView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: HomeView,
  },
  {
    path: '/',
    name: 'index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/index/IndexView'),
    children: [
      {
        path: '',
        name: 'indexSon',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/index/IndexSon.vue')
      },
      {
        path: 'reindex',
        name: 'reindex',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Recruitings/ReindexView.vue'),
        children: [
          {
            path: '',
            name: 'recruiting',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '../views/Recruitings/Recruiting/RecruitingView')
          },
          {
            path: 'detail',
            name: 'detail',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '../views/Recruitings/Recruiting/DetailView.vue')
          }
        ]
      }
    ]
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("../views/Err/NotFound.vue"),
    // component: NotFound,
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
// const VueRouterPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push (to) {
//     return VueRouterPush.call(this, to).catch(err => err);
// }

//路由拦截
router.beforeEach((to,from,next)=>{
  let nextRoute = ['index','reindex','recruiting','detail','indexSon'];
  //是否是登录中
  let token=sessionStorage.getItem('token');
  if(nextRoute.indexOf(to.name) >= 0){
    if(!token){
      router.push('/login')
    }
  }
  next();
})
export default router
